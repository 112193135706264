<template>
  <router-view></router-view>
</template>

<script>
import '@/assets/css/inti.css'

export default {
  name: 'App',
  data: () => {
    return {
      direction: 'ltr',
      drawer: false,
      wrapperClosable: true
    }
  },
}
</script>

<style>
body {
  overflow-x: hidden;
}
.el-button--primary {
  background-color: #3F79FD;
}
</style>
