import axios from 'axios'
import xz from "@/assets/js/xuanzi";
import {Message} from "element-ui";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 50000
})

service.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${xz.getCookie('token')}`
    return config;
}, error => {
    return Promise.reject(error);
});
service.interceptors.response.use(res => {
    switch (parseInt(res.data.code)) {
        case 200:
            if (res.data.message !== 'success') Message.success(res.data.message);
            return res.data;
        case 401:
            Message.error(res.data.message);
            location.href = "/login";
            break;
        case 404:
            Message.error(res.data.message);
            break;
        case 500:
            Message.error(res.data.message)
            break;
    }
    return res.data;
}, err => Promise.reject(err))

export default service
