import request from '@/utils/request'

const prefix = '/api/admin/user';

export default {
    login(data) {
        return request({
            url: `${prefix}/login`,
            method: 'post',
            data,
        })
    },
    logout() {
        return request({
            url: `${prefix}/logout`,
            method: 'delete',
        })
    },
    isLogin() {
        return request({
            url: `${prefix}/isLogin`,
            method: 'get',
        })
    },
    getUserInfo() {
        return request({
            url: `${prefix}/`,
            method: 'get',
        })
    },
    getAllUserList() {
        return request({
            url: `${prefix}/all`,
            method: 'get',
        })
    },
    getPaginateList(data) {
        return request({
            url: `${prefix}/list`,
            method: 'post',
            data
        })
    },
    remove(id) {
        return request({
            url: `${prefix}/${id}`,
            method: 'delete',
        })
    },
    modify(data) {
        return request({
            url: `${prefix}/`,
            method: 'put',
            data
        })
    },
    add(data) {
        return request({
            url: `${prefix}/`,
            method: 'post',
            data
        })
    },
    resetPassword(data) {
        return request({
            url: `${prefix}/resetPassword`,
            method: 'put',
            data
        })
    },
    points(payeeId, points) {
        return request({
            url: `${prefix}/${payeeId}/${points}`,
            method: 'get',
        })
    },
    listRecord(data) {
        return request({
            url: `${prefix}/listRecord`,
            method: 'post',
            data,
        })
    },
    refreshRecord(id){
        return request({
            url: `${prefix}/refreshRecord/${id}`,
            method: 'get',
        })
    },
    recordAll(){
        return request({
            url: `${prefix}/recordAll`,
            method: 'get',
        })
    },
    getInviteUserList(){
        return request({
            url: `${prefix}/inviteUserList`,
            method: 'get',
        })
    }
}
