import request from '@/utils/request'

const prefix = '/api/menu';

export default {
    getMenuRoute() {
        return request({
            url: `${prefix}/`,
            method: 'get',
        })
    },
    hasPermission(data) {
        return request({
            url: `${prefix}/hasPermission`,
            method: 'get',
            params: {menuRoute: data}
        })
    },
}
