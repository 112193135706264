import VueRouter from "vue-router";
import user from "@/api/user";
import xz from "@/assets/js/xuanzi";
import menu from "@/api/menu";
import {Message} from "element-ui";

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: "/", redirect: "/login"
        },
        {
            path: '/login', component: () => import('@/views/login/index.vue'),
            meta: {title: '用户登录', path: '/login',},
        },
        {
            path: "/dashboard", component: () => import('@/views/dashboard/index.vue'),
            meta: {title: '寄售商城', path: '/dashboard'},
            children: [
                {
                    path: "/", redirect: "/dashboard/workplace"
                },
                {
                    path: "workplace", component: () => import('@/views/dashboard/workplace/index.vue'),
                    meta: {title: '首页信息', path: '/dashboard/workplace',}
                },
                {
                    path: "goods/goodsAdd",
                    component: () => import('@/views/dashboard/goods/goodsAdd/index.vue'),
                    meta: {title: '新增商品', path: '/dashboard/goods/goodsAdd',}
                },
                {
                    path: "goods/goodsPool",
                    component: () => import('@/views/dashboard/goods/goodsPool/index.vue'),
                    meta: {title: '推送商品', path: '/dashboard/goods/goodsPool',}
                },
                {
                    path: "goods/goodsShelf",
                    component: () => import('@/views/dashboard/goods/goodsShelf/index.vue'),
                    meta: {title: 'T单商品配置', path: '/dashboard/goods/goodsShelf',}
                },
                {
                    path: "goods/goodsNoShelf",
                    component: () => import('@/views/dashboard/goods/goodsNoShelf/index.vue'),
                    meta: {title: '流拍商品配置', path: '/dashboard/goods/goodsNoShelf',}
                },
                {
                    path: "goods/goodsSplit",
                    component: () => import('@/views/dashboard/goods/goodsSplit/index.vue'),
                    meta: {title: '拆分商品配置', path: '/dashboard/goods/goodsSplit',}
                },
                {
                    path: "goods/goodsStock",
                    component: () => import('@/views/dashboard/goods/goodsStock/index.vue'),
                    meta: {title: '入库商品配置', path: '/dashboard/goods/goodsStock',}
                },
                {
                    path: "order/producer",
                    component: () => import('@/views/dashboard/order/producer/index.vue'),
                    meta: {title: '买方订单', path: '/dashboard/order/producer',}
                },
                {
                    path: "order/consumer",
                    component: () => import('@/views/dashboard/order/consumer/index.vue'),
                    meta: {title: '寄售订单', path: '/dashboard/order/consumer',}
                },
                {
                    path: "order/details",
                    component: () => import('@/views/dashboard/order/details/index.vue'),
                    meta: {title: '结算订单', path: '/dashboard/order/details',}
                },
                {
                    path: "user/configuration",
                    component: () => import('@/views/dashboard/user/configuration/index.vue'),
                    meta: {title: '用户配置', path: '/dashboard/user/configuration',}
                },
                {
                    path: "user/captain",
                    component: () => import('@/views/dashboard/user/captain/index.vue'),
                    meta: {title: '团长配置', path: '/dashboard/user/captain',}
                },
                {
                    path: "user/record",
                    component: () => import('@/views/dashboard/user/record/index.vue'),
                    meta: {title: '团长充值记录', path: '/dashboard/user/record',}
                },
                {
                    path: "system/configuration",
                    component: () => import('@/views/dashboard/system/configuration/index.vue'),
                    meta: {title: '系统配置', path: '/dashboard/system/configuration',}
                },
                {
                    path: "system/profit",
                    component: () => import('@/views/dashboard/system/profit/index.vue'),
                    meta: {title: '分佣配置', path: '/dashboard/system/profit',}
                },
                {
                    path: "system/carousel",
                    component: () => import('@/views/dashboard/system/carousel/index.vue'),
                    meta: {title: '主图配置', path: '/dashboard/system/carousel',}
                },
                {
                    path: "system/exhibit",
                    component: () => import('@/views/dashboard/system/exhibit/index.vue'),
                    meta: {title: '展品配置', path: '/dashboard/system/notice',}
                },
                {
                    path: "system/lever",
                    component: () => import('@/views/dashboard/system/lever/index.vue'),
                    meta: {title: '溢价配置', path: '/dashboard/system/lever',}
                },
                {
                    path: "system/auction",
                    component: () => import('@/views/dashboard/system/auction/index.vue'),
                    meta: {title: '抢拍配置', path: '/dashboard/system/auction',}
                },
            ]
        },
        {
            path: "*", redirect: "/"
        },
    ]
})

let whitelist = ['/login', '/dashboard/workplace'];
router.afterEach(async (to) => {
    let token = xz.getCookie('token');
    if (!whitelist.includes(to.path) && token != null && (await user.isLogin()).data) {
        if (!(await menu.hasPermission(to.path)).data) {
            Message.error('您没有权限访问该页面！')
            router.push('/dashboard/workplace')
        }
    }
    if (to.path === '/login' && token != null && (await user.isLogin()).data) router.push('/dashboard/workplace')
    document.title = "寄售商城-" + to.meta.title || '寄售商城-后台管理系统';
})

export default router;
