import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import VueRouter from 'vue-router'
import router from "@/router/index";
import * as echarts from 'echarts';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import VueAnimateNumber from 'vue-animate-number'


Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.use(mavonEditor)
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.use(VueAnimateNumber)

new Vue({
    router, render: h => h(App),
}).$mount('#app')
