window.addEventListener('load', () => {
    // xz.theme('#theme')
    // 初始化主题
    xz.moves('moves')
    // 鼠标阴影
    console.log('xuanzi.loaded');
})

class xz {
    /**
     * 向 :roo 即 html 绑定 theme 主题属性，默认为 light
     * 选择一个 按钮 进行主题切换
     * */
    static theme = (selector) => {
        document.querySelector(':root').setAttribute('theme', 'light')
        document.querySelector(selector).addEventListener('click', () => xz.toggleAttr(':root', 'theme', 'dark', 'light'))
    }
    /**
     * 鼠标阴影跟随 传入绑定元素 id
     * 配套样式
     * #moves {
     *     width: 20px;
     *     height: 20px;
     *     z-index: 9998;
     *     position: fixed;
     *     margin-top: -10px;
     *     margin-left: -10px;
     *     border-radius: 50%;
     *     pointer-events: none;
     *     --xz-matrix: 888, -30;
     *     mix-blend-mode: normal;
     *     transition: all 0.32s ease-out;
     *     background: rgba(0, 0, 0, 0.3);
     *     border: 3px solid rgba(0, 0, 0, 0.1);
     *     box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
     *     transform: matrix(1, 0, 0, 1, var(--xz-matrix));
     * }
     * */
    static moves = (moves) => {
        document.body.insertBefore(new DOMParser().parseFromString(`<div id="${moves}"></div>`, 'text/html').body.firstChild, document.body.firstChild);
        document.addEventListener('mousemove', (e) => document.querySelector("#moves").style = `--xz-matrix:${e.clientX},${e.clientY}`);
    }
    /**
     * 选择一个元素 对其 class 类名进行 toggle 循环
     * 适用于仅对值判断存在
     * */
    static toggleClass = (selector, activeName) => {
        document.querySelector(selector).classList.toggle(activeName)
    }
    /**
     * 选择一个元素 对其 attribute 属性进行 toggle 循环
     * 需要属性名称，以及循环的两个属性值
     * 适用于仅对值循环判断
     * */
    static toggleAttr = (selector, theme, v1, v2) => {
        let element = document.querySelector(selector);
        element.getAttribute(theme) === v1 ? element.setAttribute(theme, v2) : element.setAttribute(theme, v1);
    }
    static getCookie = (cookieName) => {
        let cookie = document.cookie.split('; ').filter(cookie => cookie.startsWith(cookieName));
        return cookie.length > 0 ? cookie[0].split('=')[1] : null;
    }
    static setCookie = (cookieName, cookieValue, expireInMinutes = 30) => {
        document.cookie = `${cookieName}=${cookieValue}; expires=${new Date(Date.now() + 60 * 1000 * expireInMinutes).toUTCString()}`;
    }
    static delCookie = (cookieName) => {
        document.cookie = `${cookieName}=; expires=${new Date(0).toUTCString()}`;
    }
    static maskSymbol = (encrypt, start = 3, length = 6, symbol = "*") => {
        const substring = encrypt.substring(start, start + length);
        return encrypt.replace(substring, symbol.repeat(length - 1));
    }
    static getFormatDateTime = (now) => {
        var year = now.getFullYear();
        var month = ("0" + (now.getMonth() + 1)).slice(-2); // getMonth() returns month from 0 to 11
        var day = ("0" + now.getDate()).slice(-2);
        var hours = ("0" + now.getHours()).slice(-2);
        var minutes = ("0" + now.getMinutes()).slice(-2);
        var seconds = ("0" + now.getSeconds()).slice(-2);
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }
    static getFormatToday = (now) => {
        var year = now.getFullYear();
        var month = ("0" + (now.getMonth() + 1)).slice(-2); // getMonth() returns month from 0 to 11
        var day = ("0" + now.getDate()).slice(-2);
        return year + "-" + month + "-" + day;
    }
}

export default xz;
